import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditoriaListComponent } from './auditoria-list/auditoria-list.component';

const routes: Routes = [
  {
    path: '',
    component: AuditoriaListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditoriaRoutingModule {}

