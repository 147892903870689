import { Injectable } from '@angular/core';
import { ProxyService } from '../providers/proxy/proxy.service';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {
  private apiName = 'bptRestApi01';
  private path = '/audit';

  constructor(private proxy: ProxyService) {}

  public async getAuditList(fechaDesde: string, fechaHasta: string, pagina: number) {
    const url = `${this.path}/?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&pagina=${pagina}`;
    return await this.proxy.get(url);
  }

  public async getAuditListExcel(fechaDesde: string, fechaHasta: string, excel: boolean) {
    const url = `${this.path}/?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&excel=${excel}`;
    return await this.proxy.get(url);
  }
}
