import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'daysSince'
})
export class DaysSincePipe implements PipeTransform {

  transform(fechaCreacion: string, fechaModificacion: string, estado: string): number {
    const start = moment(fechaCreacion);
    let end;
    if (estado === 'Cerrado') {
      end = moment(fechaModificacion);
    } else {
      end = moment(); // Fecha actual
    }

    return end.diff(start, 'days');
  }

}
