import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuditoriaRoutingModule } from './auditoria-routing.module';
import { AuditoriaListComponent } from './auditoria-list/auditoria-list.component';

@NgModule({
  declarations: [
    AuditoriaListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,  // Importa FormsModule aquí
    AuditoriaRoutingModule
  ]
})
export class AuditoriaModule {}

